/* src/App.css */
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&family=Nunito:wght@400;700&display=swap');

:root {
  --primary-color: #FF9933;
  --secondary-color: #33CCFF;
  --accent-color: #FF3366;
  --background-color: #F0F4F8;
  --text-color: #333333;
}

body {
  font-family: 'Nunito', sans-serif;
  color: var(--text-color);
  margin: 0;
  padding: 0;
  line-height: 1.6;
  background: linear-gradient(45deg, #ff00cc, #3333ff, #00ccff, #33ff33, #ffff00, #ff3300);
  background-size: 400% 400%;
  animation: disco-gradient 15s ease infinite;
}

@keyframes disco-gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.App {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 15px;
}

h1 {
  font-family: 'Fredoka One', cursive;
  font-size: 4rem;
  color: var(--primary-color);
  margin-bottom: 0;
  text-shadow: 3px 3px var(--accent-color);
  text-align: center;
}

.tagline {
  font-size: 1.2rem;
  color: var(--secondary-color);
  font-weight: bold;
  text-align: center;
  animation: color-change 15s ease infinite;
}

@keyframes color-change {
  0% { color: red; }
  50% { color: blue; }
  100% { color: red; }
}

h2 {
  font-family: 'Fredoka One', cursive;
  color: var(--accent-color);
}

.about, .venmo, .signup, .drawing{
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.about:hover, .signup:hover {
  transform: translateY(-5px);
}

form {
  display: flex;
  flex-direction: column;
}

input[type="email"] {
  padding: 15px;
  margin-bottom: 15px;
  border: 2px solid var(--secondary-color);
  border-radius: 50px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

input[type="email"]:focus {
  outline: none;
  border-color: var(--accent-color);
}

.drawing-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

}

button {
  padding: 15px;
  border: none;
  border-radius: 50px;
  background-color: var(--primary-color);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

button:hover {
  background-color: var(--accent-color);
  transform: scale(1.05);
}

button:active {
  transform: scale(0.95);
}

.message {
  margin-top: 15px;
  padding: 15px;
  border-radius: 50px;
  background-color: var(--secondary-color);
  color: white;
  font-weight: bold;
  text-align: center;
}

footer {
  text-align: center;
  margin-top: 40px;
  font-size: 0.9rem;
  color: var(--primary-color);
  animation: color-change 15s ease infinite;
}

@media (max-width: 600px) {
  h1 {
    font-size: 3rem;
  }
  
  .App {
    padding: 10px;
  }
}